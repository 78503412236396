import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <div className="legal">
      <p>
        Copyright ©2023 All rights reserved <i className="fa fa-heart"></i> by
        Majed Smichi
      </p>
    </div>
  );
};
export default Footer;
